.new-campagnes-wrapper{
	.header {
		display: flex;
		align-items: center;
		justify-content: space-between;
		flex-wrap: wrap;
		padding: 10px 50px;
		.top-bar-wrapper{
			display: flex;
			justify-content: space-between;
			align-items: center;
			flex-wrap: wrap;
			.title {
			color: $dark-pink;
			font-weight: 600;

			.segment-title {
				color: black;
			}
		}
		.button-wrapper{
			margin-left: 20px;
			.button {
				color: #fff;
				background: $dark-pink;
				border: 2px solid $dark-pink;
				border-radius: 3px;
				height: 40px;
				width: 170px;
				font-size: 18px;
				color: white;
				a{
					color: white;
				}

				a:hover {
					text-decoration: none;
					color: white;
				}

				&:hover {
					background: $dark-gray;
					border: 2px solid $dark-gray;
				}

				&.disabled {
					background: #CCC;
					border-color: #CCC;
				}
			}
		}
	}
		
		
		.name {
			font-weight: 600;
		}
	}

	.input-date-field{

		.date-time-icons {
		    display: flex;
		    align-items: center;
		    padding: .375rem .75rem;
		    font-size: 1rem;
		    border-radius: .25rem;
	        background-color: #e50051;
		    border: none;
		    color: white;		

		    img {
		    	height: 20px;
		    }    
		}
		.date-icons{
			border-top-right-radius: 0;
    		border-bottom-right-radius: 0;
		}		
		.time-icon {
			border-top-left-radius: 0;
    		border-bottom-left-radius: 0;
		}
		.below-field-label {
			font-weight: 400px;
			font-size: 14px;
			float: left;
			color: #898889;
			margin-top: -3px;
		}
	}

	.label-wrapper{
		display: flex;
		justify-content: space-between;
		border-bottom: 1px solid #CCC;
		margin-bottom: 6px;

		.field-label{
		    color: rgba(0, 0, 0, 0.65);
		    font-weight: bold;
		    margin-bottom: -2px;

		    .colored-label {
		    	color: #d1245e;
		    }
		}
	}

		.connected-by-wrapper {
			text-align: left;

			.ant-radio-button-wrapper {
				color: white;
				background: $dark-gray;
				border: 0px solid $dark-pink;
				margin-right: 10px;
				border-radius: 4px;
			}
			.ant-radio-button-wrapper::before {
				background: none;
			}

			.ant-radio-button-wrapper:focus {
				outline: none;
			}
			.ant-radio-button-wrapper:last-child {
				border-radius: 4px;
			}

			.ant-radio-button-wrapper:first-child {
				border-radius: 4px;
				margin-right: 10px;
			}

			.ant-radio-button-wrapper:hover {
				background: $dark-pink;
				border: 0px solid $dark-pink;
				color: white;
			}

			.ant-radio-button-wrapper-checked {
				background: $dark-pink;
				border: 0px solid $dark-pink;
				color: white;
				box-shadow: none;
			}
		}
	

	.date-time-wrapper {
		display: flex;
		justify-content: space-between;
		height:65px;
	}

	.buttons-wrapper {
		display: flex;
		justify-content: space-around;
		margin-top: 40px;

		.anuler {
			width: 130px;
			color: white;
			background: #43444a;
			border: 2px solid $dark-pink;
			border-radius: 4px;
			cursor: pointer;
			height: 40px;
			margin-left: 70px;
		}
		.valider {
			width: 130px;
			color: #fff;
			background: $dark-pink;
			border: 2px solid $dark-pink;
			border-radius: 4px;
			cursor: pointer;
			height: 40px;			
			margin-left: 40px;
		}
	}

	.input-shipment-field {
		.checkbox-wrapper {
			display: flex;
		}
		.checkbox-label {
			background: lightgray;
		    padding: 7px;
		    border-radius: 3px 0px 0px 3px;
		    width: 100%;
		}
		.checkbox-item {			
			display: flex;
			align-items: center;
			background: white;
			padding: 0px 10px;
			border: 1px solid lightgray;
			border-radius: 0px 3px 3px 0px;

			.ant-checkbox-inner {
				background: white;
			}
		}
	}

	.input-sendSms-field{

	.sendSms-wrapper {
				text-align: left;

				.ant-radio-button-wrapper {
					color: white;
					background: $dark-gray;
					border: 0px solid $dark-pink;
					margin-right: 10px;
					border-radius: 4px;
				}
				.ant-radio-button-wrapper::before {
					background: none;
				}

				.ant-radio-button-wrapper:focus {
					outline: none;
				}
				.ant-radio-button-wrapper:last-child {
					border-radius: 4px;
				}

				.ant-radio-button-wrapper:first-child {
					border-radius: 4px;
					margin-right: 10px;
				}

				.ant-radio-button-wrapper:hover {
					background: $dark-pink;
					border: 0px solid $dark-pink;
					color: white;
				}

				.ant-radio-button-wrapper-checked {
					background: $dark-pink;
					border: 0px solid $dark-pink;
					color: white;
					box-shadow: none;
				}
			}
		}

		.ant-select-selection-selected-value {
			color: white;
		}


	.right-wrapper {
		display: flex;
		justify-content: flex-start;
		//background: url("../img/apleframe.png");
		//background-size: cover;

		.mobile-content-wrapper {
			background: url("../img/apleframe.png");
			background-size: cover;
			height:400px;
			width: 200px;
			padding-top: 56px;
		    padding-left: 24px;
		    padding-right: 20px;

			.subject-box {
				display: flex;
				justify-content: center;

				.subject {
					font-weight: 700;
					font-size: 16px;
				}
			}
			.message-box {
				margin-top: 10px;
				display: flex;
				justify-content: flex-start;
				.message {
					text-align: left;
				}
			}
			.stop-at-text {
				text-align: start;
			}
		}
	}

	.ant-select-lg .ant-select-selection--single {
		background: $dark-pink;			
	}
	.ant-select-arrow svg {
		color: white;
	}

}