.edit-sale-wrapper {

	.edit-sale-header {
		display: flex;
		justify-content: space-between;

		.title {
			color: $dark-pink;
			font-weight: 600;			
		}
		.italic {
			font-size: 14px;
		}
	}

	.editsale-content-wrapper {
		margin-top: 25px;

		.page-title {
			font-size: 18px;
			text-align: start;
			margin-bottom: 20px;
		}
		.row-wrapper {
			.form-item {
				.input-group {
				    display: flex;
				    flex-wrap: nowrap;
				    align-items: center;				    				    
				}
				.user-icon {
			    	background: white;;
			    	padding: 5px 10px 4px 10px;
			    	border: 1px solid lightgray;
			    	border-radius: 5px 0px 0px 5px;
				 }
				.common-icons {
					background-color: #d1245e;
				    border: none;
				    color: white;
				    padding: 6px 15px;
				    border-radius: 5px 0px 0px 5px;
				    font-size: 18px;
				}
				.date-icon {
					background-color: #d1245e;
				    border: none;
				    color: white;
				    padding: 9px 13px;
				    border-radius: 5px 0px 0px 5px;

				    img {
				    	height: 20px;
				    }
				}
			}
			.form-item {
				margin-top: 7px;				
			}
			.field-title {
					font-weight: 700;
					text-align: left;
					border-bottom: 1px solid lightgray;;
			}
			.editSale-valider {
				background-color: #d1245e;
				border-color: #d1245e; 
			}
			.editSale-annuler {
				background-color: #afaaaa;
				border-color: #d1245e;
				color: black;
				border-color: white;
				border-bottom: 3px solid black;
			}

			&.first-row-wrapper {
				margin-bottom: 20px;
			}
		}
	}
}