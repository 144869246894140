.create-compaign-wrapper {
    margin-bottom: 70px;
    .create-compaign-wrapper-content {
        .compaign-heading {
            display: flex;
            justify-content: space-between;
            align-items: center;
            flex-wrap: wrap;
            padding-bottom: 10px;
            padding-top: 10px;
            font-weight: 500;
            .heading-text {
                color: #e50051;
                font-weight: 600;
                font-size: 20px;
            }
        }
        .left-content-wrapper {
            @include respond-at(480px) {
                display: flex;
                justify-content: center;
                align-items: center;
            }
            .mobile-content-wrapper {
                background: url('../img/apleframe.png');
                background-size: cover;
                height: 400px;
                width: 200px;
                padding-top: 56px;
                padding-left: 24px;
                padding-right: 20px;
                .subject-box {
                    display: flex;
                    justify-content: center;
                    .subject {
                        font-weight: 700;
                        font-size: 16px;
                    }
                }
                .message-box {
                    margin-top: 10px;
                    display: flex;
                    justify-content: flex-start;
                    .message {
                        text-align: left;
                    }
                }
                .stop-at-text {
                    text-align: start;
                }
            }
        }

        .right-content-wrapper {
            .first-heading {
                font-size: 25px;
                font-weight: 600;
                letter-spacing: 1px;
            }
            .message-box {
                font-size: 40px;
                font-weight: 900;
                padding-top: 5px;
                .colored-digit {
                    color: #e50051;
                    font-size: 50px;
                }
            }
            .amount-rate {
                font-size: 25px;
                font-weight: 500;
                letter-spacing: 1px;
            }
        }
        .strip-wrapper {
            .DemoPickerWrapper {
                padding: 0 12px;
                font-family: 'Source Code Pro', monospace;
                box-shadow: 0 4px 6px rgba(50, 50, 93, 0.11),
                    0 1px 3px rgba(0, 0, 0, 0.08);
                border-radius: 3px;
                background: white;
                margin: 24px 0 48px;
                width: 100%;
            }

            .DemoPicker {
                font-size: 18px;
                border-radius: 3px;
                background-color: white;
                height: 48px;
                font-family: Helvetica Neue, Helvetica, Arial, sans-serif;
                border: 0;
                width: 100%;
                color: #6772e5;
                outline: none;
                background: transparent;

                -webkit-appearance: none;
            }

            .DemoWrapper {
                margin: 0 auto;
                max-width: 500px;
                padding: 0 24px;
                display: flex;
                flex-direction: column;
                height: 100vh;
            }

            .Demo {
                flex: 1;
                display: flex;
                flex-direction: column;
                justify-content: center;
                padding-bottom: 40%;
            }

            label {
                color: #6b7c93;
                font-weight: 300;
                letter-spacing: 0.025em;
                width: 100%;
            }

            button {
                white-space: nowrap;
                border: 0;
                outline: 0;
                display: inline-block;
                height: 40px;
                line-height: 40px;
                padding: 0 14px;
                box-shadow: 0 4px 6px rgba(50, 50, 93, 0.11),
                    0 1px 3px rgba(0, 0, 0, 0.08);
                color: #fff;
                border-radius: 4px;
                font-size: 15px;
                font-weight: 600;
                text-transform: uppercase;
                letter-spacing: 0.025em;
                //   background-color: #6772e5;
                background-color: #e50051;
                text-decoration: none;
                -webkit-transition: all 150ms ease;
                transition: all 150ms ease;
                /*margin-top: 10px;*/
            }

            button:hover {
                // color: #fff;
                cursor: pointer;
                // background-color: #7795f8;
                transform: translateY(-1px);
                box-shadow: 0 7px 14px rgba(50, 50, 93, 0.1),
                    0 3px 6px rgba(0, 0, 0, 0.08);
            }

            input,
            .StripeElement {
                display: block;
                margin: 10px 0 20px 0;
                max-width: 500px;
                padding: 10px 14px;
                font-size: 1em;
                font-family: 'Source Code Pro', monospace;
                box-shadow: rgba(50, 50, 93, 0.14902) 0px 1px 3px,
                    rgba(0, 0, 0, 0.0196078) 0px 1px 0px;
                border: 0;
                outline: 0;
                border-radius: 4px;
                background: white;
            }

            input::placeholder {
                color: #aab7c4;
            }

            input:focus,
            .StripeElement--focus {
                box-shadow: rgba(50, 50, 93, 0.109804) 0px 4px 6px,
                    rgba(0, 0, 0, 0.0784314) 0px 1px 3px;
                -webkit-transition: all 150ms ease;
                transition: all 150ms ease;
            }

            .StripeElement.IdealBankElement,
            .StripeElement.FpxBankElement,
            .StripeElement.PaymentRequestButton {
                padding: 0;
            }

            .StripeElement.PaymentRequestButton {
                height: 40px;
            }
        }
        .atm-cards-wrapper {
            display: flex;
            justify-content: center;
            align-items: center;
            margin-top: 25px;
            img {
                width: 70px;
                height: 40px;
                margin: 5px;
            }
        }
        .button-wrapper {
            margin-top: 40px;
            .button {
                @include respond-at(768px) {
                    padding: 10px 30px;
                }
                padding: 10px 70px;
                letter-spacing: 1px;
                font-weight: 500;
                font-size: 20px;
                border: 2px solid #e50051;
                background: #696969;
            }
        }
    }
}
