@import 'colors.scss';
@import 'mixins.scss';

.default-wrapper {
	
	.header {
		display: flex;
		align-items: center;
		justify-content: space-between;
		padding: 10px 50px;

		.title {
			//color: #d1215e;
			color: #e50051;
			font-weight: 600;
		}
		.name {
			font-weight: 600;
		}
	}

	.box-col {
		@include respond-at(768px){
		    max-width: 100%;
    		flex: 0 0 100%;
		}
	}
	.box-wrapper {
		//background: $dark-pink;
		background: #e50051;
	    border-radius: 15px;
	    color: #FFF;
	    height: 190px;
	    width: 280px;
	    margin: 0 auto;
	    display: flex;
	    flex-direction: column;
	    align-items: center;
	    justify-content: center;
	    margin-top: 20px;
	    font-weight: 550;
	    font-size: 26px;
	    cursor: pointer;

	    a {
	    	color: #fff;
		    padding: 0px;
		    text-decoration: none;
	    }
	}	

	.box-wrapper:hover {
		background: $dark-gray;
	}

	.news-wrapper {
		background-color: #f2dede;
		//background-color: #D3D3D3;;
		border-color: #c0392b;
		color: #c0392b;
		padding: 15px;
		border-left: 4px solid;
		width: 65%;
		margin: auto;
		margin-top: 60px;
		font-size: 14px;
		line-height: 12px;
		text-align: left;

		@include respond-at(768px){
		    margin-top: 20px;
		    //width: 480px;
		}

		@include respond-at(560px){
		    width: 280px;
		}

		.news-item {
			text-align: left;
			padding: 10px 10px 20px;
			background: #efcdcd;
			//background: #D3D3D3;

			img {
				height: 30px;
			    margin-right: 5px;
			    //margin-top: -5px;
			}

			.title-wrapper {
				font-weight: 600;
				display: flex;
			    flex-direction: row;
			    align-items: center;
			    justify-content: space-between;
			    //color: #808080;
			    color: $dark-gray;

			    .hr {
					font-size: 14px;
				}
			}

			.time-wrapper {
				display: flex;
				align-items: center;
				justify-content: space-between;
				padding-left: 27px;
				.time {
					font-size: 10px;
				}
				.hr {
					font-size: 10px;
				}
			}
			.content {
				font-size: 14px;
				padding-left: 44px;
				//color: #808080;
				color: $dark-gray;
			}

			
		}
	}
}