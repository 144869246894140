.new-sms5-wrapper{
	display: flex;
	justify-content: flex-start;
	margin-top: 20px;
	.sms-heading{
		display: flex;
		justify-content: flex-start;
		//font-weight: 500;
		font-size: 20px;
	}
	.sms-secd-heading{
		display: flex;
		justify-content: flex-start;
		font-weight: 500;
		font-size: 20px;
		margin-bottom: 20px;
	}
	.label-wrapper{
		display: flex;
		justify-content: space-between;
		border-bottom: 1px solid #CCC;
		margin-bottom: 6px;

		.field-label{
		    color: rgba(0, 0, 0, 0.65);
		    font-weight: bold;
		    margin-bottom: -2px;

		    .colored-label {
		    	color: #d1245e;
		    }
		}
	}
	.input-date-field{

		.date-time-icons {
		    display: flex;
		    align-items: center;
		    padding: .375rem .75rem;
		    font-size: 1rem;
		    border-radius: .25rem;
	        background-color: #e50051;
		    border: none;
		    color: white;		

		    img {
		    	height: 20px;
		    }    
		}
		.date-icons{
			border-top-right-radius: 0;
    		border-bottom-right-radius: 0;
		}		
		.time-icon {
			border-top-left-radius: 0;
    		border-bottom-left-radius: 0;
		}
		.below-field-label {
			font-weight: 400px;
			font-size: 14px;
			float: left;
			color: #898889;
			margin-top: -3px;
		}
	}
	.sms-list-wrapper{
		margin-top: 20px;
		.sms-list-heading{
			text-align: left;
			color: white;
			font-size: 20px;
			font-weight: normal;
			background: #e50051;
			padding: 10px 30px 10px 30px;
			width: 55%;
		}
	}
	.sms-nbr-wrapper{
		width: 55%;
		margin-top: 20px;
		display: flex;
		//justify-content: flex-start;
		align-items: center;
		//flex-direction: row;
		background: #e50051;
		//width: 38%;
		//height: 70px;
		.sms-list-heading{
			color: white;
			font-size: 20px;
			font-weight: normal;
			background: #e50051;
			padding: 20px 20px 20px 20px;
		}
		.input-field{
			@media only screen and (max-width: 768px){
					.react-tel-input .form-control{
					width: 200px;
				}
			}
			
			.phone-field {
				//width: 300px;
			}
		}
		.image{
			margin-left: 10px;
			img{
				width: 30px;
				height: 30px;
			}
		}
	}

	.buttons-main-wrapper {
		float: right;
		margin-top: 60px;
		.buttns-wrapper{
			display: flex;
			justify-content: flex-end;
			align-items: center;
		
			.valider {
				width: 130px;
				color: #fff;
				background: $dark-pink;
				padding: 10px 0px;
				cursor: pointer;
				margin-left: 10px;
			}
			.anular {
				width: 130px;
				color: #fff;
				background: #43444a;
				padding: 10px 0px;
				cursor: pointer;
			}
		}
	}
}