.sms-vocal-message-wrapper{
	display: flex;
	justify-content: flex-start;
	margin-top: 20px;
	.sms-heading{
		display: flex;
		justify-content: flex-start;
		//font-weight: 500;
		font-size: 20px;
	}
	.sms-secd-heading{
		display: flex;
		justify-content: flex-start;
		font-weight: 500;
		font-size: 20px;
		margin-bottom: 20px;
	}
	.label-wrapper{
		display: flex;
		justify-content: space-between;
		border-bottom: 1px solid #CCC;
		margin-bottom: 6px;

		.field-label{
		    color: rgba(0, 0, 0, 0.65);
		    font-weight: bold;
		    margin-bottom: -2px;

		    .colored-label {
		    	color: #d1245e;
		    }
		}
	}
	.input-date-field{

		.date-time-icons {
		    display: flex;
		    align-items: center;
		    padding: .375rem .75rem;
		    font-size: 1rem;
		    border-radius: .25rem;
	        background-color: #e50051;
		    border: none;
		    color: white;		

		    img {
		    	height: 20px;
		    }    
		}
		.date-icons{
			border-top-right-radius: 0;
    		border-bottom-right-radius: 0;
		}		
		.time-icon {
			border-top-left-radius: 0;
    		border-bottom-left-radius: 0;
		}
		.below-field-label {
			font-weight: 400px;
			font-size: 14px;
			float: left;
			color: #898889;
			margin-top: -3px;
		}
	}
	.sms-list-wrapper{
		margin-top: 20px;
		display: flex;
		background: #e50051;
		justify-content: flex-start;
		.sms-list-heading{
			color: white;
			font-size: 25px;
			font-weight: 300;
			letter-spacing: 1px;
			//background: #e50051;
			padding: 5px 0px 5px 10px;
			.first-box-text{
				font-size: 20px;
				float: left;
				letter-spacing: 0px;
			}
		}
		.second-box-heading{
			color: white;
			font-size: 25px;
			font-weight: 300;
			letter-spacing: 1px;
			//background: #e50051;
			padding: 5px 0px 5px 10px;
			display: flex;
			align-items: center;
		}
	}
	.input-sendSms-field{
		margin-top: 40px;
	.sendSms-wrapper {
				text-align: left;

				.ant-radio-button-wrapper {
					color: white;
					background: $dark-gray;
					border: 0px solid $dark-pink;
					margin-right: 10px;
					border-radius: 4px;
					padding: 0px 20px 0px 20px;
				}
				.ant-radio-button-wrapper::before {
					background: none;
				}

				.ant-radio-button-wrapper:focus {
					outline: none;
				}
				.ant-radio-button-wrapper:last-child {
					border-radius: 4px;
				}

				.ant-radio-button-wrapper:first-child {
					border-radius: 4px;
					margin-right: 10px;
				}

				.ant-radio-button-wrapper:hover {
					background: $dark-pink;
					border: 0px solid $dark-pink;
					color: white;
				}

				.ant-radio-button-wrapper-checked {
					background: $dark-pink;
					border: 0px solid $dark-pink;
					color: white;
					box-shadow: none;
				}
			}
		}

		.ant-select-selection-selected-value {
			color: white;
		}
		.date-time-wrapper {
			display: flex;
			justify-content: space-between;
			height:65px;
			//margin-bottom: 80px;
		}

	.content-wrapper {
		//padding: 40px 50px;
		margin-top: 30px;
		.segments-table {
			.ant-table-thead > tr > th {
				font-size: 12px;
				background: #e50051;
				padding-top: 10px;
				padding-bottom: 10px;
				color: white;
				font-size: 16px;
			}
			.actions-wrapper{
				display: flex;
				flex-direction: row;
				align-items: center;
				justify-content: space-around;

				.nav-link {
					color: rgba(0, 0, 0, 0.65);
				}
				.action-icon {
					cursor: pointer;
				}
			}

			.status-switch {
				width: 68px;
			}
		}
	}
	.image-wrapper{
		padding: 0px;
		float: left;
		//margin-right: 200px;
		margin-top: 30px;
		display: flex;
		justify-content: flex-start;
		flex-direction: column;
		.image1{
			margin-right: 110px;
			// display: flex;
			// justify-content: flex-start;
			// flex-direction: row;

			img{
				margin-top: 10px;
				width: 50px;
				height: 40px;
			}
			.image-inner{
				margin-bottom: -20px;
			}
		}
		.image2{
			//margin-left: 90px;
			// display: flex;
			// justify-content: flex-start;
			// flex-direction: row;

			img{
				margin-top: 10px;
				width: 50px;
				height: 40px;
			}
			.image-inner{
				margin-bottom: -20px;
			}
		}
		.image3{
			margin-right: 30px;
			// display: flex;
			// justify-content: flex-start;
			// flex-direction: row;

			img{
				margin-top: 10px;
				width: 50px;
				height: 40px;
			}
			.image-inner{
				margin-bottom: -20px;
			}
		}
	}
	.btns-wrapper{
		margin-top: 60px;
		display: flex;
		justify-content: center;
		align-items: center;
		padding-right: 10px;
	
	.valider {
		//width: 130px;
		color: #fff;
		width: auto;
		background: $dark-pink;
		padding: 10px 30px 10px 30px;
		margin-right: 20px;
		margin-left: 20px;
		margin-bottom: 25px;
		//border: 2px solid $dark-pink;
		//border-radius: 4px;
		cursor: pointer;
		//height: 40px;			
		//margin-left: 40px;
	}
	.anular {
		//width: 130px;
		color: #fff;
		width: auto;
		background: #43444a;
		padding: 10px 25px 10px 25px;
		margin-right: 20px;
		margin-left: 20px;
		margin-bottom: 25px;
		//border: 2px solid $dark-pink;
		//border-radius: 4px;
		cursor: pointer;
		//height: 40px;			
		//margin-left: 40px;
	}
}
}
