@import 'mixins.scss';

.header-con-popover-wrapper {
	background: #e50051;
	margin-right: 5px;
	.item-wrapper{
		color: white;
		background: #e50051;
		.menu-item-wrapper{
			font-weight: 500;
			.ant-dropdown-menu-item{

			}
		}	
	}
	.item-wrapper:hover{
		background: #e50051;
	}
	
}
.header-con-popover-wrapper:hover{
	background: #e50051;
}
.header-wrapper {
	background: #fff;
	border: 0 none;
	border-radius: 0;
	background-clip: padding-box;
	margin: 0;
	min-height: 80px;
	color: #262626;
	box-shadow: 0px 1px 3px 0 rgba(0, 0, 0, 0.1);
	position: relative;
	z-index: 99;

	// @include respond-at(991px){
	//     min-height: 90px;
	// }

	.header-container {
		//width: 100%;

		// @include respond-at(767px){
		//     padding-left: 5px;
		// 	padding-right: 5px;
		// }

		.logo-wrapper {
			//background: #34495e;
			color: #fff;
			font-family: 'Titillium Web',Geneva,sans-serif;
			font-weight: 700;
			
			margin-left: -8px;
			padding: 0px 55px 10px 15px;
			float: left;
			height: 50px;
			font-size: 18px;
			line-height: 20px;
			@include respond-at(991px){
			    background: transparent;
				color: #262626;
				// padding: 10px 15px; 
				padding: 0px 15px 10px 15px;
			}
			
			img {
				float: left;
				padding-right: 4px;
				height: 30px;

				@include respond-at(767px){
				    height: 25px;
				}

			}
			// .logo-image{
			// 	width: 100px;
			// 	height: 100px;
			// }
			.normal-logo {
				&.logo-white {
					height: 100px;
					margin-left: 10px;
					padding-bottom: 5px;
					@include respond-at(991px){
					    //display: none;
					}

					@include respond-at-min(992px){
					    display: block;
					}	
				}
				&.logo-black {
					@include respond-at(991px){
					    display: block;
					}

					@include respond-at-min(992px){
					    display: none;
					}
				}
			}
		}

		.menu-wrappper {
			float: right;
			display: flex;
			justify-content: space-between;
			padding-top: 8px;

			.nav-link {
				padding: 0px;
				color: rgb(38, 38, 38);
				display: flex;
				flex-direction: column;
			    align-items: center;
			    justify-content: flex-end;
			}
			.menu-item {
				padding: 0 30px;
				display: flex;
			    flex-direction: column;
			    align-items: center;
			    justify-content: flex-end;
			    cursor: pointer;
					font-size: 26px;
					
					@include respond-at(800px){
						padding:0 25px;
					}
						@include respond-at(750px){
							padding:0 20px;
						}
						@include respond-at(700px){
							padding:0 15px;
						}
						@include respond-at(650px){
							padding:0 10px;
						}
						
				// }
			    

				.avatar{
					.avatar-img {
						height: 25px;
						border-radius: 25px;
					}
				}

				.label {
					//font-size: 12px;
					font-size: 14px;
					padding: 4px 0; 
				}

				&.hide-me {
					@include respond-at(550px){
					    display: none;
					}
				}

				&.show-me {
					@include respond-at-min(550px){
					    display: none;
					}
				}
			}
		}

		.clear {
			clear: both;
		}
	}
}

.ant-dropdown {
	.menu-item-wrapper {
		display: flex;
		flex-direction: row;
		align-items: center;
		justify-content: flex-start;		

		.label {
			padding-left: 10px;			
		}
		.menu-icon {
			width: 20px;
		}
	}
	.ant-dropdown-menu-item:hover {
		//background: #efcdcd;
		background: #f2dede;
	}
}