.stats-wrapper {

	.header {
		padding: 10px 50px;

		.title {
			color: $dark-pink;
			font-weight: 600;
			text-align: left;

			.italic {
				font-size: 14px;
				font-weight: normal;
				color: #000;
				font-style: italic;
			}
		}
	}

	.table-wrapper {
		margin: 10px 0px;
		.table {
			.top-row {
				th {
					border-top: 0px;
				}				
			}
			th {				
				color: #e50051;
				font-weight: 600;
			}
			tbody {
				th {
					text-align: left;
				}
			}
		}
	}

	.dashboard-wrapper {
		margin: 30px 0px;

		.dashboard-item {
				
			.ant-progress-text {
				font-weight: 400;
				color: #e50051;
			}
			
			.points-title {
				font-size: 18px;
				font-weight: 700;
				color: #e50051;
				margin-top: -20px;
			}
		}
		.label {
			color: #e50051;
			font-weight: 700;
			font-size: 20px;
		}
	}
}
