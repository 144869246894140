/*------------------------------------------
  Responsive Grid Media Queries - 1280, 1024, 768, 480
   1280-1024   - desktop (default grid)
   1024-768    - tablet landscape
   768-480     - tablet 
   480-less    - phone landscape & smaller
   	@media all and (min-width: 1024px) and (max-width: 1280px) { }
 
	@media all and (min-width: 768px) and (max-width: 1024px) { }
	 
	@media all and (min-width: 480px) and (max-width: 768px) { }
	 
	@media all and (max-width: 480px) { }
--------------------------------------------*/



@mixin respond-at($media) {
    @media only screen and (max-width: $media){ @content; }
}

@mixin respond-at-min($media) {
    @media only screen and (min-width: $media){ @content; }
}