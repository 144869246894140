.actions-wrapper {
    .header {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 10px 50px;

        .title {
            //color: #d1215e;
            color: #e50051;
            font-weight: 600;

            .italic {
                font-size: 14px;
                font-weight: normal;
                color: #000;
                font-style: italic;
            }
        }
    }
    .dashboard-wrapper {
        margin-top: 50px;

        .dashboard-item {
            .ant-progress-text {
                font-weight: 400;
                color: #d1245e;
            }

            .points-title {
                font-size: 18px;
                font-weight: 700;
                color: #d1245e;
                margin-top: -20px;
            }
        }
        .button-wrapper {
            .button-item {
                color: #fff;
                //background: #d1215e;
                background: #e50051;
                border: 2px solid #e50051;
                border-radius: 3px;
                height: 50px;
                width: 170px;
                font-size: 18px;

                a:hover {
                    text-decoration: none;
                }

                &:hover {
                    background: $dark-gray;
                    border: 2px solid $dark-gray;
                }

                &.disabled {
                    background: #ccc;
                    border-color: #ccc;
                }
            }
        }
    }
    .field-button-wrapper {
        display: flex;
        justify-content: center;
        align-items: center;
        margin-top: 80px;

        .select-wrapper {
            width: 400px;
            .segment-error {
                color: red;
                font-size: 16px;
            }

            .ant-select-selection--single {
                //background: #d1215e;
                background: $dark-pink;
            }
            .ant-select-arrow svg {
                color: white;
            }
            .ant-select-selection__placeholder {
                font-weight: bold;
            }
            .ant-select-selection-selected-value {
                font-weight: bold;
                color: white;
            }
        }
        .button-item {
            margin-left: 20px;

            button {
                color: #fff;
                background: $dark-pink;
                border: 2px solid $dark-pink;
                border-radius: 3px;
                height: 35px;
                font-size: 18px;

                a:hover {
                    text-decoration: none;
                }

                &:hover {
                    background: $dark-gray;
                    border: 2px solid $dark-gray;
                }
            }
        }
    }
}
.ant-select-dropdown-menu-item {
    a {
        text-decoration: none;
        color: gray;
    }
    a:hover {
        text-decoration: none;
        color: White;
    }
    .createSegment-link-wrapper {
        display: flex;
        align-items: center;

        .edit-icon {
            margin-right: 5px;
        }
    }
}

.ant-select-dropdown-menu-item-group-title {
    //background: #d1215e;
    background: $dark-pink;
    width: 70px;
    text-align: center;
    margin: 5px;
    border-radius: 5px;
    color: white;
}
.ant-select-dropdown-menu-item-group-title:hover {
    //background: #424449;
    background: $dark-gray;
    cursor: pointer;
}
