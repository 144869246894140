.clients-list-wrapper {
	.header {
		display: flex;
		align-items: center;
		justify-content: space-between;
		padding: 10px 50px;

		.title1 {
			//color: #d1215e;
			color: $dark-pink;
			font-weight: 600;

			.italic {
				font-size: 14px;
				font-weight: normal;
				color: #000;
				font-style: italic;
			}
		}

		.right-panel {
			display: flex;
			align-items: center;
			justify-content: flex-end;

			.add-tag-clientb {
				color: #fff;
				//background: #d1215e;
				background: $dark-pink;
				border: 2px solid $dark-pink;
				margin-left: 10px;

				.nav-link {
					padding: 0px;
					color: #fff;
				}

				&:hover {
					//background: #424449;
					background: $dark-gray;
					border: 2px solid $dark-gray;
				}
			}
		}
	}

	.content-wrapper {
		padding: 40px 50px;

		.clients-table {
			.ant-table-thead > tr > th {
				font-size: 12px;
			}
			.actions-wrapper{
				display: flex;
				flex-direction: row;
				align-items: center;
				justify-content: space-around;

				.nav-link {
					color: rgba(0, 0, 0, 0.65);
				}
				.action-icon {
					cursor: pointer;
				}
			}

			.status-switch {
				width: 68px;
			}
		}
	}
}