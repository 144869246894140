#sms-campaign-summary-wrapper {
    .mobile-content-wrapper {
        background: url('../img/apleframe.png');
        background-size: cover;
        // height: 400px;
        // width: 200px;
        padding-top: 56px;
        padding-left: 24px;
        padding-right: 20px;

        .subject-box {
            display: flex;
            justify-content: center;

            .subject {
                font-weight: 700;
                font-size: 16px;
            }
        }
        .message-box {
            margin-top: 10px;
            display: flex;
            justify-content: flex-start;
            .message {
                text-align: left;
            }
        }
        .stop-at-text {
            text-align: start;
        }
    }

    .valider-button {
        //width: 130px;
        color: #fff;
        width: auto;
        background: $dark-pink;
        padding: 15px 15px 15px 15px;
        // margin-right: 20px;
        // margin-left: 20px;
        // margin-bottom: 25px;
        //border: 2px solid $dark-pink;
        //border-radius: 4px;
        cursor: pointer;
        //height: 40px;
        //margin-left: 40px;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
    }
    .anular-button {
        //width: 130px;
        color: #fff;
        width: auto;
        background: #43444a;
        padding: 15px 15px 15px 15px;
        // margin-right: 20px;
        // margin-left: 20px;
        // margin-bottom: 25px;
        //border: 2px solid $dark-pink;
        //border-radius: 4px;
        cursor: pointer;
        //height: 40px;
        //margin-left: 40px;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
    }
}

.pagination-last-button span {
    margin-right: 8px;
}
