.new-sale-wrapper {
	.nav-link {
		padding: 0px;
		color: #fff;
	}

	.header {
		display: flex;
		align-items: center;
		justify-content: space-between;
		padding: 10px 50px;

		.title {
			color: $dark-pink;
			font-weight: 600;

			.italic {
				font-size: 14px;
				font-weight: normal;
				color: #000;
				font-style: italic;
			}
		}

		.right-panel {
			display: flex;
			align-items: center;
			justify-content: flex-end;

			.add-tag-client {
				color: #fff;
				background: $dark-pink;
				border: 2px solid $dark-pink;
				margin-left: 10px;

				.nav-link {
					padding: 0px;
					color: #fff;
				}

				&:hover {
					background: $dark-gray;
					border: 2px solid $dark-gray;
				}
			}
		}
	}

	.content-wrapper {
		padding-top: 50px;
		padding-bottom: 100px;
		width: 90%;
		margin: auto;

		.heading {
			text-align: left;
    		font-weight: 500;
		}


		
		.new-client-btn {
			float: right;
			background-color: $dark-pink;
			border: none;
			margin-bottom: 10px;
		}



		.date-icon {
			background-color: $dark-pink;
			border: none;
			color: white; 

			img {
				height: 20px;
			}
		}

		.name-field {
			padding-top: 22px;
			.name-icon {
				background-color: #d1245e;
				border: none;
				color: white;
			}
		}

		.num-right-icon, .num-left-icon {
			font-size: 23px;
			font-weight: bold;
		}

		.num-right-icon {
			background-color: $dark-pink;
			border: none;
			color: white;
		}

		.selects-right-icon {
			background-color: $dark-pink;
			border: none;
			color: white;
			height: 40px;
			border-top-left-radius: 0px;
			border-bottom-left-radius: 0px;
		}

		.question-mark {
		    background-color: #d1245e;
		    display: inline-block;
		    border-radius: 50%;
		    width: 22px;
		    text-align: center;
		    color: white;
		}

		.validate-btn {
			background-color: $dark-pink;
			border: none;
		}
		
		.validate-btn:hover {
			background-color: $dark-gray;
		}

		.cancel-btn {
			background-color: #afaaaa;
			border: none;
			padding: 7px 22px;
			text-align: center;
			display: inline-block;
			font-size: 18px;
			border-radius: 3px;
			border-bottom: 4px solid black;
			float: right;
			margin-top: 20px;
			cursor: pointer;
		}

		.custom-selects-wrapper {
			display: flex;
			flex-direction: row;
			align-items: center;
			justify-content: center;
			margin-bottom: 6px;

			.selects-field {
				font-size: 20px;
				//font-weight: bold;
				//height: 50px;
			}

			.montant-field {

			}

			.addon-button {
				.input-group-text {
					font-size: 23px;
    				font-weight: bold;
    				cursor: pointer;
				}
				
			}

			&.client-select-field {
				margin-top: 21px;
			}
		}
		.ant-form-explain {
			text-align: left;
		}

		.date-picker {
			.ant-input-lg {
				font-size: 20px;
			}
		}

		.ammout-field-wrapper {
			.ant-input-number-lg {
				height: 48px;
				flex: 1;
				
				input {
					height: 48px;
					font-size: 20px;
				}
			}
		}

		.left-box-wrapper {
			.ant-calendar-picker {
				flex: 1;
			}

			.customer-details-box {
				.customer-title {
					margin-bottom: 8px;
				}
				.icon-btn {
					background-color: $dark-pink;
					border: none;
					width: 100%;
					border-radius: 3px;
    				font-size: 14px;
    				margin: 6px 0;
    				padding: 2px;
				}

				.progress-bar-wrapper {
					margin: 5px 0 10px;
					.ant-progress-text {
						position: absolute;
						left: 0;
						right: 0;
						margin: auto;
						line-height: 20px;
						color: #fff;
    					top: 2px;
    					font-weight: 500;
					}
					.ant-progress-outer {
						margin: 0px;
						padding: 0px;

						.ant-progress-inner {
							border-radius: 0px;
							//background-color: #EEEEEE;

							.ant-progress-bg {
								height: 20px !important;
								border-radius: 0px;
								background-color: #d1245e;
							}
						}
						
					}
				}

				.dashboard-bar-wrapper {
					margin: 10px 0;
					
					.ant-progress-text {
						font-weight: 700;
						color: #d1245e;
					}
					
					.points-title {
						font-size: 14px;
						font-weight: 600;
						color: $dark-pink;
						margin-top: -20px;
					}
					
				}
				a {
					text-decoration:none;
					.checque-title{
						background:#d1245e;
						.checque-btn{
							padding:7px 0px 3px 0px;
							text-align:center;
							font-size: 14px;
							font-weight: 600;
							color:white;
							margin-top: 10px;
						}
					}
				}	
			}
		}

	}
	
	.italic {
		font-weight: normal;
		font-style: italic;
	}

}


.ant-select-dropdown-menu-item {
	padding: 5px 10px;
	.sale-clients-list-item {
		.header {
			display: flex;
			flex-direction: row;
			justify-content: space-between;

			.title {
				font-size: 14px;
				font-weight:  600;
			}

			.carte {
				font-size: 12px;
				font-weight: 300;
				font-style: italic;
			}
		}
		.mobile {
			font-size: 12px;
		}
	}

	.sale-clients-list-item-active {
		display: none;	
	} 

	&:hover {
		//background: #d1245e !important;
		background: $dark-pink !important;
		color: #FFF;
	}
}

.ant-select-selection-selected-value {
	.sale-clients-list-item {
		display: none;
		.header {
			display: flex;
			flex-direction: row;
			justify-content: space-between;

			.title {
				font-size: 14px;
				font-weight:  600;
			}

			.carte {
				font-size: 12px;
				font-weight: 300;
				font-style: italic;
			}
		}
		.mobile {
			font-size: 12px;
		}
	}
}
