.test-sms-campagne-modal {
	.box-wrapper {
		height: 100%;
		display: flex;
		flex-direction: column;
		align-items: center;
		margin-top: 30px;

		.title {
			font-size: 18px;
			font-weight: bold;
			border-bottom: 1px solid lightgray;
			margin-bottom: 10px;
			width: 100%;
			text-align: center;
		}
		.number-field {
			width: 100%;
			.react-tel-input {
				.form-control {
					width: 100%;	
				}
				.flag-dropdown {
					display: none;
				}
			}
		}
		.model-text {
			font-weight: 500;
			color: grey;
		}
		.send-button {
			.ant-btn {
				background: #e50051;
				color: white;
				border-color: #e50051;
				letter-spacing: 1px;
				font-size: 16px;
				font-weight: 500;
				height: 50px;
			}
		}
	}

	.test-message-wrapper {
		margin-top: 30px;

		.msg-title {
			font-weight: bold;
		}
		.msg-content {
			font-size: 16px;
		}
	}
}
