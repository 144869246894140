@import 'mixins.scss';

.create-account-wrapper {
	background: url(../img/bg-recovery.png) repeat;
	background-size: cover;
	width: 100%;
	position: absolute;
	height: 100%;
	@include respond-at(767px){
	    height: 100%;
	}
	
	.container {
		background: url(../img/bg-recovery.png) repeat;
	}

	.error-msg { 
		text-align: left;
	    padding: 0 4px;
	    color: red;
	    margin-top: -10px;
	}

	.accept-error { 
		margin-top: 0px;
	}
	#login-box {
		max-width: 350px;
		min-width: 280px;
		margin: 60px auto 20px;
		overflow: hidden;
		border-radius: 3px 3px 0 0;
		background-clip: padding-box;
		@include respond-at(767px){
		    margin-top: 20px;
		}
		line-height: 1.4em;
    	font-size: 14px;

		#login-box-holder {
			#login-header {
				#login-logo {
					background: none repeat scroll 0 0 #34495e;
					color: #fff;
					display: block;
					font-size: 2em;
					font-weight: 400;
					padding: 35px 0;
					text-align: center;
					text-transform: uppercase;
				}

				#login-logo > img {
					display: block;
					height: 80px;
					margin: 0 auto;
				}
			}

			#login-box-inner {
				background: #fff;
				border-radius: 0 0 3px 3px;
				background-clip: padding-box;
				border: 1px solid #e1e1e1;
				border-bottom-width: 5px;
				padding: 40px 25px;

				&.with-heading {
					padding-top: 20px;
				}

				.title {
					font-weight: 300;
					text-align: left;
					margin-bottom: 10px;
				}

				.description {
					text-align: left;
					margin-bottom: 10px;
				}

				.submit-btn {
					background: #e50051;
					border: 2px solid #e50051;
					font-size: 14px;
					width: 100%;
					border-radius: 4px;
					font-weight: 600;
					padding-bottom: 10px;
					padding-top: 10px;
					text-transform: uppercase;
					margin-top: 8px;
					color: #FFF;
					height: 46px;

					&.disabled {
						opacity: 0.6;					
					}
				}

				.submit-btn:focus {
					outline: none;
				}

				.submit-btn:hover {
					background: #424449;
					border: 2px solid #424449
				}

				.retour-link {

					.nav-link {
						text-align: right;
						color: #424449;
						font-size: 0.875em;
						margin-top: 20px;
					}
					
				}

				.retour-link:hover {
					text-decoration: underline;
				}


				.input-field-wrapper {
					display: flex;
					flex-direction: row;
					align-items: center;
					justify-content: flex-start;
					position: relative;
					margin: 10px 0;

					.left-icon-wrapper {
						height: 46px;
						width: 46px;
						min-width: 46px;
						border: 1px solid #CCC;
						border-top-left-radius: 4px;
						border-bottom-left-radius: 4px;
						display: flex;
						flex-direction: row;
						align-items: center;
						justify-content: center;

						.fa {
							color: #424449;
							display: inline-block;
							font-family: FontAwesome;
							font-style: normal;
							font-weight: normal;
							line-height: 1;
							-webkit-font-smoothing: antialiased;
						}

						.fa-user:before {
							content: "\f007";
						}
					}

					.field-wrapper {
						height: 46px;
						width: 100%;
						border: 1px solid #CCC;
						border-left: 0px;
						display: flex;
						flex-direction: row;
						align-items: center;
						justify-content: flex-start;
						

						.input-field {
							border: none;
							height: 44px;
							color: #999999;
							padding-left: 12px;
							padding-right: 10px;
							width: 100%;
							font-size: 14px;
						}

						.input-field:focus {
							outline: none;
						}

						.field-icon {
							position: absolute;
						    height: 22px;
						    right: 2px;

						}
					}
				}

				.accept-checkbox-wrapper {
					text-align: left;
					margin: 10px 0 2px 0;

					.ant-checkbox {
						margin-right: 10px;

						.ant-checkbox-input {

						}
					}
				}
			}
		}
	}
}