.new-segment-wrapper {
	
	.content-wrapper {
		padding-left: 20px;
		padding-right: 20px;
		
		.header {
			display: flex;
			//align-items: center;
			justify-content: space-between;

			.left-panel {
				display: flex;
				//align-items: center;
				justify-content: flex-end;

				.input-field-segment{
					width: 350px;
				}
				
				.title{
					color: $dark-pink;
					font-weight: bold;
					font-size: 16px;
					margin-right: 5px;
					margin-top: 6px;
				}
			}

			.right-panel {

				.enseigne {
					font-size: 16px;
					color: $dark-pink;
					font-weight: bold;
				}
			}
		}

		.select-wrapper {
			margin-top: 25px;

			.select-label {
				padding-top: 5px;
				font-weight: 450;
			}

			.radio-label {

				.rounded-question-mark {
					background-color: #d1245e;
				    display: inline-block;
				    border-radius: 50%;
				    width: 22px;
				    text-align: center;
				    color: white;
				}
			}

			.radio-wrapper {
				display: flex;
				//border: 1px solid black;
				flex-direction: flex-start;
				justify-content:column;
				align-items:center;

				.ant-radio-button-wrapper {
	
					color: white;
					height:30px;
					background: $dark-gray;
					border: 0px solid $dark-pink;
					margin-right: 10px;
					border-radius: 4px;
				}
				.ant-radio-button-wrapper::before {
					background: none;
				}

				.ant-radio-button-wrapper:focus {
					outline: none;
				}
				.ant-radio-button-wrapper:last-child {
					border-radius: 4px;
				}

				.ant-radio-button-wrapper:first-child {
					border-radius: 4px;
					margin-right: 10px;
				}

				.ant-radio-button-wrapper:hover {
					background: $dark-pink;
					border: 0px solid $dark-pink;
					color: white;
				}

				.ant-radio-button-wrapper-checked {
					background: $dark-pink;
					border: 0px solid $dark-pink;
					color: white;
					box-shadow: none;
				}
				.et-radio-btn {
					margin-top: 5px;	
				}
			}
		}		

		.range-wrapper {
			display: flex;
		    justify-content: space-around;
		    align-items: center;

			.range-item	{
				//width: 30%;

				.label {
					font-weight: 600;
					border-bottom: 1px solid #CCC;
    				padding: 0 10px;
				}

				.slider-wrapper {
					height: 20px;
					.ant-slider {
						.ant-slider-rail {
							height: 16px;
						}
						.ant-slider-track {
							height: 16px;
							background: #d1245e;
							border: #A3A3A3 1px solid;
						}
						.ant-slider-step {
							height: 16px;
						}

						.ant-slider-handle {
							height: 24px;
							width: 30px;
							border-radius: 2px;
							background-repeat: no-repeat;
							background-image: url('../img/slider.png');
							background-position: center;
	    					background-size: 100%;
	    					border: solid 1px #D9D9D9;

	    					&:focus {
	    						box-shadow: 0 0 0 5px rgba(163, 163, 163, 0.2);
	    					}
						}
					}
				}
				
			}			
			.below-slider {
				display: flex;
				justify-content: space-between;
				align-items: center;
				margin-top: 6px;

				.periode {
					font-size: 12px;
				}

				.check-box {
					label {
						font-size: 12px;
						font-weight: 500;
					}
					
				}
			}
		}

		.dashboard-wrapper {
			display: flex;
			justify-content: space-between;
			padding: 0 60px 0 60px;
				.dashboard-item {
					
					.ant-progress-text {
						font-weight: 400;
						color: #d1245e;
					}
					
					.points-title {
						font-size: 18px;
						font-weight: 700;
						color: #d1245e;
						margin-top: -20px;
					}
				}
		}

		.buttons-summary-wrapper {
			display: flex;
			justify-content: space-between;
			margin-top: 45px;

			.summary-wrapper{
				text-align: left;
				font-weight: 600;

				.colored-text {
					color: #d1245e;
				}
			}

			.buttons-wrapper {
				display: flex;
				
				.valider {
					color: #fff;
					background: $dark-pink;
					border: 2px solid $dark-pink;
					border-radius: 4px;
					margin-right: 10px;
					cursor: pointer;
					height: 40px;
				}
				.valider:hover {
					background: $dark-gray;
					border: 2px solid $dark-gray;
				}
				.anuler {
					color: #333;
					background: #C0C0C0;
					border-bottom: 4px solid;
					border-radius: 4px;
					margin-right: 10px;
					cursor: pointer;
					height: 40px;
				}
			}

		}
	}
}