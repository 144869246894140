.stats-campagnes-wrapper{
	.content-wrapper {
		.stats-heading{
			font-size: 40px;
			margin-bottom: 10px;
		}
		margin-top: 30px;
		.segments-table {
			.ant-table-thead > tr > th {
				font-size: 12px;
				background: #e50051;
				padding-top: 10px;
				padding-bottom: 10px;
				color: white;
				font-size: 16px;
			}
			.actions-wrapper{
				display: flex;
				flex-direction: row;
				align-items: center;
				justify-content: space-around;

				.nav-link {
					color: rgba(0, 0, 0, 0.65);
				}
				.action-icon {
					cursor: pointer;
				}
			}

			.status-switch {
				width: 68px;
			}
		}
	}
}