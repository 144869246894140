.sms-mobile-test-wrapper{
	display: flex;
	justify-content: flex-start;
	margin-top: 20px;

	.left-content-wrapper{
		margin-top: 20px;
		@include respond-at(480px){
			display: flex;
		    justify-content: center;
		    align-items: center;
		}
		.mobile-content-wrapper {
			background: url("../img/apleframe.png");
			background-size: cover;
			height:400px;
			width: 200px;
			padding-top: 56px;
		    //padding-left: 24px;
		    padding-left: 20px;
		    padding-right: 20px;
			.subject-box {
				display: flex;
				height: 30px;
				background: #F0F0F0;
				justify-content: center;
				margin-top: 0px;
				margin-bottom: 20px;
				.subject {
					font-weight: 700;
					font-size: 16px;
				}
			}
			.message-box {
				//margin-top: 10px;
				display: flex;
				flex-direction: column;
				justify-content: center;
				align-items: center;
				.img-playbutton{
					height: 80px;
					cursor: pointer;
					width: 80px
				}
				.voice-caption{
					margin-top: 10px;
					font-size: 13px;
				}
			}
			.tester-wrapper{
				//height: 134px;
				height: 125px;
				margin-top: 7px;
				width: auto;
				background: #F0F0F0;
				.tester{
					padding-top: 10px;
					font-size: 15px;
					font-weight: bold;
				}
				.sms-nbr-wrapper{
					width: 55%;
					margin-top: 0px;
					display: flex;
					//justify-content: flex-start;
					align-items: center;
					//flex-direction: row;
					//background: #e50051;
					//width: 38%;
					//height: 70px;
					.sms-list-heading{
						color: white;
						font-size: 20px;
						font-weight: normal;
						background: #e50051;
						padding: 20px 20px 20px 20px;
					}
					.input-field{
						@media only screen and (max-width: 768px){
								.react-tel-input .form-control{
								width: 130px;
							}
						}
						.react-tel-input .form-control{
							width: 160px;
						}
						
						.phone-field {
							//width: 300px;
						}
						.has-error .ant-form-explain, .has-error .ant-form-split{
							color: red;
						}
						.ant-form-item-children-icon{
							display: none;
						}
					}
					.image{
						margin-left: 10px;
						img{
							width: 20px;
							height: 20px;
						}
					}
				}
			}
			.submit-button{
				color: white;
				cursor: pointer;
				margin-top: 12px;
				background: #e50051;

			}
		}	
	}
	
	// .sms-heading{
	// 	display: flex;
	// 	justify-content: flex-start;
	// 	//font-weight: 500;
	// 	font-size: 20px;
	// }
	.sms-secd-heading{
		display: flex;
		justify-content: flex-start;
		font-weight: 500;
		font-size: 20px;
		margin-bottom: 20px;
	}
	.right-panel-wrapper{
		.sms-list-wrapper{
			margin-top: 20px;
			//margin-bottom: 20px;
			margin-left: 40px;
			display: flex;
			//justify-content: flex-start;
			.sms-list-heading{
				color: white;
				font-size: 20px;
				font-weight: normal;
				background: #e50051;
				padding: 10px 120px 10px 10px;
			}
		}
		.left-wrapper {
			float: left;
			display: flex;
			justify-content: flex-start;
			flex-direction: column;
			margin-left: 40px;
			.text{
				height: 40px;
				display: flex;
				justify-content: flex-start;
			}
		}
		.right-wrapper{
			float: left;
			display: flex;
			justify-content: flex-start;
			flex-direction: column;
			.text {
				padding-top: 1px;
				height: 40px;
				display: flex;
				justify-content: flex-start;
			}
		}
	}
	
		
	
	.btns-wrapper{
		margin-top: 30px;
		display: flex;
		justify-content: center;
		align-items: center;
		padding-right: 10px;
	
	.valider {
		//width: 130px;
		color: #fff;
		width: auto;
		background: $dark-pink;
		padding: 10px 25px 10px 25px;
		margin-right: 20px;
		margin-left: 20px;
		margin-bottom: 25px;
		//border: 2px solid $dark-pink;
		//border-radius: 4px;
		cursor: pointer;
		//height: 40px;			
		//margin-left: 40px;
	}
	.anular {
		//width: 130px;
		color: #fff;
		width: auto;
		background: #43444a;
		padding: 10px 25px 10px 25px;
		margin-right: 20px;
		margin-left: 20px;
		margin-bottom: 25px;
		//border: 2px solid $dark-pink;
		//border-radius: 4px;
		cursor: pointer;
		//height: 40px;			
		//margin-left: 40px;
	}
}
}