.client-tags-wrapper {
	.header {
		display: flex;
		align-items: center;
		justify-content: space-between;
		padding: 10px 50px;

		.title {
			color: $dark-pink;
			font-weight: 600;

			.italic {
				font-size: 14px;
				font-weight: normal;
				color: #000;
				font-style: italic;
			}
		}

		.right-panel {
			display: flex;
			align-items: center;
			justify-content: flex-end;

			.add-tag-client {
				color: #fff;
				background: $dark-pink;
				border: 2px solid $dark-pink;
				margin-left: 10px;

				&:hover {
					background: $dark-gray;
					border: 2px solid $dark-gray;
				}
			}
		}
	}

	.content-wrapper {
		padding: 40px 50px;

		.tags-table {
			.actions-wrapper{
				display: flex;
				flex-direction: row;
				align-items: center;
				justify-content: space-around;

				.action-icon {
					cursor: pointer;
				}
			}

			.status-switch {
				width: 68px;
			}
		}
	}
}
