.import-sms-wrapper5{
	display: flex;
	justify-content: flex-start;
	margin-top: 20px;

	.left-content-wrapper{
		@include respond-at(480px){
			display: flex;
		    justify-content: center;
		    align-items: center;
		}
		.mobile-content-wrapper {
			background: url("../img/apleframe.png");
			background-size: cover;
			height:400px;
			width: 200px;
			padding-top: 56px;
		    padding-left: 24px;
		    padding-right: 20px;
			.subject-box {
				display: flex;
				justify-content: center;
				.subject {
					font-weight: 700;
					font-size: 16px;
				}
			}
			.message-box {
				margin-top: 10px;
				display: flex;
				justify-content: flex-start;
				
			}
		}	
	}
	.left-wrapper{
		float: left;
		display: flex;
		justify-content: flex-start;
		flex-direction: column;
		.text{
			margin-top: 10px;
			display: flex;
		justify-content: flex-start;
		}
	}
	.right-wrapper{
		float: left;
		display: flex;
		justify-content: flex-start;
		flex-direction: column;
		.text{
			margin-top: 10px;
			display: flex;
		justify-content: flex-start;
		}
	}
	// .sms-heading{
	// 	display: flex;
	// 	justify-content: flex-start;
	// 	//font-weight: 500;
	// 	font-size: 20px;
	// }
	.sms-secd-heading{
		display: flex;
		justify-content: flex-start;
		font-weight: 500;
		font-size: 20px;
		margin-bottom: 20px;
	}
	
	
	.sms-list-wrapper{

		margin-bottom: 20px;
		display: flex;
		justify-content: center;
		.sms-list-heading{
			color: white;
			font-size: 20px;
			font-weight: normal;
			background: #e50051;
			padding: 10px 85px 10px 85px;
		}
	}
	
	.btns-wrapper{
		margin-top: 100px;
		display: flex;
		justify-content: center;
		align-items: center;
		padding-right: 10px;
	
	.valider {
		//width: 130px;
		color: #fff;
		width: auto;
		background: $dark-pink;
		padding: 10px 25px 10px 25px;
		margin-right: 20px;
		margin-left: 20px;
		margin-bottom: 25px;
		//border: 2px solid $dark-pink;
		//border-radius: 4px;
		cursor: pointer;
		//height: 40px;			
		//margin-left: 40px;
	}
	.anular {
		//width: 130px;
		color: #fff;
		width: auto;
		background: #43444a;
		padding: 10px 25px 10px 25px;
		margin-right: 20px;
		margin-left: 20px;
		margin-bottom: 25px;
		//border: 2px solid $dark-pink;
		//border-radius: 4px;
		cursor: pointer;
		//height: 40px;			
		//margin-left: 40px;
	}
}
}