@import 'mixins.scss';

.login-wrapper {
	
	//background: red;
	height: 100%;

	.login-form {
		height: 100%;
		.form-wrapper {
			height: 100%;

			.left-column {
				display: flex;
				flex-direction: row;
				align-items: center;
				justify-content: center;
				margin-bottom: 20px;
				@include respond-at(575px){
				    align-items: flex-start;
				}
			}

			.right-column {
				// display: flex;
				// flex-direction: row;
				// align-items: center;
				// justify-content: center;	
				background-position: top right;
			   //background-repeat: no-repeat;
			     //background-attachment: fixed;
			 //    background-size: cover;
			    
			     -webkit-background-size: cover;
				 -moz-background-size: cover;
				 -o-background-size: cover;
				 background-size: cover;

			    @include respond-at(1024px){
				    //height: 50%;
				    //background-attachment: inherit;
				    //background-repeat: no-repeat;
			     	background-attachment: fixed;
 					background-position: top center;
				}
			}

			.fields-wrapper {
				width: 80%;
				.form-item {
					margin-bottom: 30px;

					.login-field {
						width: 100%;
					    border: none;
					    background: #DFE0DC;
					    padding: 0 10px;
					    border-radius: 8px;
					    color: #727776;
					    height: 50px;
					}

					.login-field:focus {
						outline: none;
						border: 0px;
	    				box-shadow: none;
					}


					.ant-form-explain {
						text-align: left;
					}

				}

				.login-btn {
					border: none;
					height: 50px;
					background: #e50051;	
					color: #FFF;
					width: 100%;
					border-radius: 8px;

					&.disabled {
							opacity: 0.6;					
					}
				}

				.login-btn:focus {
					outline: none;
				}

				.login-btn:hover {
					background: $dark-gray;
					border: 2px solid $dark-gray
				}

				.checkboxes-wrapper {
					display: flex;
					flex-direction: row;
					width: 100%;
					align-items: flex-start;
	    			justify-content: space-between;
					margin-bottom: 10px;
				}
			}

			.recovry-link > a {
					color: black;
					padding: 0px;
					cursor: pointer;
			}	
		}
	}
	
}