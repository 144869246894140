.import-sms-wrapper2{
	display: flex;
	justify-content: flex-start;
	margin-top: 20px;
	.sms-heading{
		display: flex;
		justify-content: flex-start;
		//font-weight: 500;
		font-size: 20px;
	}
	.sms-secd-heading{
		display: flex;
		justify-content: flex-start;
		font-weight: 500;
		font-size: 20px;
		margin-bottom: 20px;
	}
	.label-wrapper{
		display: flex;
		justify-content: space-between;
		border-bottom: 1px solid #CCC;
		margin-bottom: 6px;

		.field-label{
		    color: rgba(0, 0, 0, 0.65);
		    font-weight: bold;
		    margin-bottom: -2px;

		    .colored-label {
		    	color: #d1245e;
		    }
		}
	}
	.input-date-field{

		.date-time-icons {
		    display: flex;
		    align-items: center;
		    padding: .375rem .75rem;
		    font-size: 1rem;
		    border-radius: .25rem;
	        background-color: #e50051;
		    border: none;
		    color: white;		

		    img {
		    	height: 20px;
		    }    
		}
		.date-icons{
			border-top-right-radius: 0;
    		border-bottom-right-radius: 0;
		}		
		.time-icon {
			border-top-left-radius: 0;
    		border-bottom-left-radius: 0;
		}
		.below-field-label {
			font-weight: 400px;
			font-size: 14px;
			float: left;
			color: #898889;
			margin-top: -3px;
		}
	}
	.sms-list-wrapper{
		margin-top: 20px;
		display: flex;
		justify-content: flex-start;
		.sms-list-heading{
			color: white;
			font-size: 20px;
			background: #e50051;
			padding: 10px 85px 10px 85px;
		}
	}
	.content-wrapper {
		//padding: 40px 50px;
		margin-top: 30px;
		.segments-table {
			.ant-table-thead > tr > th {
				font-size: 12px;
				background: #e50051;
				padding-top: 10px;
				padding-bottom: 10px;
				color: white;
				font-size: 16px;
			}
			.actions-wrapper{
				display: flex;
				flex-direction: row;
				align-items: center;
				justify-content: space-around;

				.nav-link {
					color: rgba(0, 0, 0, 0.65);
				}
				.action-icon {
					cursor: pointer;
				}
			}

			.status-switch {
				width: 68px;
			}
		}
	}
	.image-wrapper{
		padding: 0px;
		justify-content: flex-start;
		display: flex;
		flex-direction: column;
		.image1{
			float: left;
			display: flex;
			align-items: center;
			flex-wrap: wrap;
			margin: 3px;
			img{
				width: 20px;
				height: 20px;
			}
			.image-inner{
				//margin-bottom: -20px;
				margin-left: 10px;
			}
		}
		.image2{
			//margin-left: 90px;
			// display: flex;
			// justify-content: flex-start;
			// flex-direction: row;

			img{
				//margin-top: 10px;
				width: 40px;
				height: 30px;
			}
			.image-inner{
				margin-bottom: -20px;
			}
		}
		.image3{
			margin-right: 30px;
			// display: flex;
			// justify-content: flex-start;
			// flex-direction: row;

			img{
				//margin-top: 10px;
				width: 30px;
				height: 40px;
			}
			.image-inner{
				//margin-bottom: -20px;
			}
		}
	}

	.buttons-wrapper{
		display: flex;
		justify-content: flex-end;
		align-items: center;
		margin-top: 50px;

		.valider {
			color: #fff;
			width: auto;
			background: $dark-pink;
			padding: 10px 25px;
			margin-left: 25px;
			border-radius: 0px;
			cursor: pointer;
			font-size: 16px;
			&.disabled {
				background: #CCC;
			}
		}
		.anular {
			padding: 10px 25px;
			cursor: pointer;
			background: #43444a;
			color: #fff;
			width: auto;

		}
	}
	
}