.top-fields-wrapper{
	.label-wrapper{
		display: flex;
		justify-content: space-between;
		border-bottom: 1px solid #CCC;
		margin-bottom: 6px;

		.field-label{
		    color: rgba(0, 0, 0, 0.65);
		    font-weight: bold;
		    margin-bottom: -2px;

		    .colored-label {
		    	color: #d1245e;
		    }
		}
	}
	.input-date-field{

		.date-time-icons {
		    display: flex;
		    align-items: center;
		    padding: .375rem .75rem;
		    font-size: 1rem;
		    border-radius: .25rem;
	        background-color: #e50051;
		    border: none;
		    color: white;		

		    img {
		    	height: 20px;
		    }    
		}
		.date-icons{
			border-top-right-radius: 0;
    		border-bottom-right-radius: 0;
		}		
		.time-icon {
			border-top-left-radius: 0;
    		border-bottom-left-radius: 0;
		}
		.below-field-label {
			font-weight: 400px;
			font-size: 14px;
			float: left;
			color: #898889;
			margin-top: -3px;
		}
	}
}