.sms-vocal-campagne-wrapper{
	display: flex;
	justify-content: flex-start;
	margin-top: 20px;
	.sms-heading{
		display: flex;
		justify-content: flex-start;
		//font-weight: 500;
		font-size: 20px;
	}
	.sms-secd-heading{
		display: flex;
		justify-content: flex-start;
		font-weight: 500;
		font-size: 20px;
		margin-bottom: 20px;
	}
	.label-wrapper{
		display: flex;
		justify-content: space-between;
		border-bottom: 1px solid #CCC;
		margin-bottom: 6px;

		.field-label{
		    color: rgba(0, 0, 0, 0.65);
		    font-weight: bold;
		    margin-bottom: -2px;

		    .colored-label {
		    	color: #d1245e;
		    }
		}
	}
	.input-date-field{

		.date-time-icons {
		    display: flex;
		    align-items: center;
		    padding: .375rem .75rem;
		    font-size: 1rem;
		    border-radius: .25rem;
	        background-color: #e50051;
		    border: none;
		    color: white;		

		    img {
		    	height: 20px;
		    }    
		}
		.date-icons{
			border-top-right-radius: 0;
    		border-bottom-right-radius: 0;
		}		
		.time-icon {
			border-top-left-radius: 0;
    		border-bottom-left-radius: 0;
		}
		.below-field-label {
			font-weight: 400px;
			font-size: 14px;
			float: left;
			color: #898889;
			margin-top: -3px;
		}
	}
	.segment{
		display: flex;
		justify-content: flex-start;
		font-weight: 500;
		.segment-inner{
			color: #e50051;
		}
	}
	.segment-amount{
		margin-top: 10px;
		display: flex;
		justify-content: flex-start;
		font-weight: 500;
		.segment-amount-inner{
			color: #e50051;
		}
	}

	.buttons-row {
		margin-top: 40px;	
		margin-bottom: 40px;
		.valider {
			color: #fff;
		   	//width: 270px;
		    background: #e50051;
		    padding: 15px 15px 15px 15px;
		    //margin: 0px 10px;
		    cursor: pointer;
		}
		
	}

	.retour-row {

		.anular {
			color: #fff;
			width: 150px;
			background: #43444a;
			padding: 8px 25px;
			cursor: pointer;
			float: right;	
		}
	}
}