.main-dashboard-wrapper {

	.header {
		display: flex;
		align-items: center;
		justify-content: space-between;
		padding: 10px 50px;

		.title {
			//color: #d1215e;
			color: #e50051;
			font-weight: 600;

			.italic {
				font-size: 14px;
				font-weight: normal;
				color: #000;
				font-style: italic;
			}
		}
	}
	.dashboard-wrapper {
		//margin-top: 50px;

		.dashboard-item {
				
			.ant-progress-text {
				font-weight: 400;
				color: #e50051;
			}
			
			.points-title {
				font-size: 18px;
				font-weight: 700;
				color: #e50051;
				margin-top: -20px;
			}
		}
		.label {
			color: #e50051;
			font-weight: 700;
			font-size: 20px;
		}
		.button-wrapper {
			.button-item {
					color: #fff;
					//background: #d1215e;
					border: 2px solid #d1215e;
					border-radius: 3px;
					height: 50px;
					width: 170px;
					font-size: 18px;

					a:hover {
						text-decoration: none;
					}

					&:hover {
						background: $dark-gray;
						border: 2px solid $dark-gray;
					}

					&.disabled {
						background: #CCC;
						border-color: #CCC;
					}
				}
		}
	}
	.statistics-wrapper{	
		margin-top: 30px;

		.statistics-header {			
			display: flex;
			align-items: center;
			justify-content: space-between;

			.title {
				//color: #d1215e;
				color: #e50051;
				font-weight: 600; 
			}

			.field-wrapper {
				display: flex;
				align-items: center;

				.icon {
					background: white;
				    border: 1px solid lightgray;
				    padding: 3px 10px 3px 10px;
				    border-right: 0px;
    				border-radius: 5px 0px 0px 5px;
				}

				.field-item {
					
					.ant-select-selection--single {
						//background: #d1215e;			
						background: $dark-pink;			
					}
					.ant-select-selection--single:active {
							box-shadow: none;
							border-color: $dark-pink;
					}
					.ant-select-selection--single:focus {
							box-shadow: none;
							border-color: $dark-pink;
					}
					.ant-select-selection--single:hover {
							box-shadow: none;
							border-color: $dark-pink;
					}
					.ant-select-arrow svg {
						color: white;
					}
					.ant-select-selection__placeholder {
						//font-weight: bold;
						color: white;
					}
					.ant-select-selection-selected-value {
						//font-weight: bold;
						color: white;
					}

					.selects-field {
						&.ant-select-open {
							.ant-select-selection {
								//background: #3482B5;
								background: #e50051;
								color: white;
							}

							.ant-select-selection:active {
								border: 0px;
								box-shadow: none;
								outline: 0;
							}

							.ant-select-selection:focus {
								border: 0px;
								box-shadow: none;
								outline: 0;
							}
							.ant-select-selection:hover {
								border: 0px;
								box-shadow: none;
								outline: 0;
							}
						}
					}
				}
			}
		}
		.statistics {
			margin: 20px 0px;
			.stat {
				//height: 100%;
				display: flex;
				align-items: center;
				justify-content: space-between;
				background: #ededed;
				padding: 10px;

				.icon {
					background: #e50051;
					padding: 10px;
					border-radius: 50%;
					width: 50px;
					height: 50px;
					display: flex;
					justify-content: center;
					align-items: center;
					opacity: 0.3;

					.field-icon {
						font-size: 24px;
						color: white;
					}
				}

				.description {
					display: flex;
					flex-direction: column;
					align-items: flex-end;
					font-weight: bold;

					.type {
						font-weight: 500;
						font-size: 15px;
					}
					.number {
						font-weight: 600;
						font-size: 18px;
					}
				}
				border-bottom: 3px solid $dark-pink;
			}
		}
	}	
		
}
.raphael-group-187-plot-group{
	rect {
		fill: #e50051;
	}
}

.raphael-group-71-plot-group{
	path {
		fill: white;
		//stroke: white;
	}
}
.raphael-group-70-common-elems-group{
	path {
		stroke: #e50051;
	}
}
.raphael-group-6-background{
	rect {

		fill: black;
	}
}
.raphael-group-69-plot-group{
	path {
		fill: white;
		//stroke: white;
	}
}
.raphael-group-68-common-elems-group{
	path {
		stroke: #e50051;
	}
}
.raphael-group-174-plot-group{
	rect{
		fill: #e50051;
	}
}

/* text  { display: none; }*/

.raphael-group-DmQBgoVP{
	text{
		display: none;
	}
}
.raphael-group-182-plot-group{
	rect{
		fill: #e50051;
	}
}
.raphael-group-178-plot-group{
	rect{
		fill: #e50051;
	}
}

.ant-input:focus{
  outline: 0;
  box-shadow: 0 0 2px #e50051 !important;
  outline: none !important;
  border: none !important;
  -webkit-box-shadow: 0 0 10px #e50051 !important;
}
.ant-calendar-picker:focus .ant-calendar-picker-input:not(.ant-input-disabled) {
  outline: 0;
  border-color: #e50051 !important;
  outline: none !important;
  border-right-width: 1px!important;
  -webkit-box-shadow: 0 0 2px #e50051 !important;
  box-shadow: 0 0 2px #e50051 !important;
}
/*raphael-group-CARkHfUv*/
/*raphael-group-lLhoVxLq*/
/*raphael-group-nKfYoNcc*/
/*raphael-group-WgOHDbGZ*/
/*raphael-group-XhMSlGyQ*/
