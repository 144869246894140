.create-clients-wrapper {
	padding: 50px;
	
	.form-item {

		&.mobile-form-field {
			.ant-form-item-children {
				position: absolute;
				width: 100%;
			}

			.ant-form-explain {
				top: 40px;
				position: absolute;
			}
		}
		.btn{

		}
		.ant-form-item-children {
			.anticon {
				&.anticon-close-circle{
					height: 14px;
    				width: 14px;
					background: url("../img/cross.png");
					background-size: contain;

					svg {
						display: none;
					}
				}
				&.anticon-check-circle {
					height: 16px;
					width: 16px;
					background: url("../img/tick.png");
					background-size: contain;

					svg {
						display: none;
					}
				}			
			}		
			
		}

		#phone-form-control {
			width: 100%;
		}

		.city-input-field {
			.ant-select-selection {
				background-color: #f6f6f6;
				border: 1px solid #ccc;
				font-weight: 600;
				font-size: 18px;

				input {
					background-color: transparent;
					border: none;
				}
			}
		}

		text-align: left;
		.label {
			text-align: left;
		}

		input {
			background-color: #f6f6f6;
			border: 1px solid #ccc;
			font-size: 18px;
    		font-weight: 600;
		}

		.sex-wrapper {
			text-align: left;

			.ant-radio-button-wrapper {
				color: white;
				background: $dark-gray;
				border: 0px solid $dark-gray;
				margin-right: 10px;
				border-radius: 4px;
			}
			.ant-radio-button-wrapper::before {
				background: none;
			}

			.ant-radio-button-wrapper:focus {
				outline: none;
			}
			.ant-radio-button-wrapper:last-child {
				border-radius: 4px;
			}

			.ant-radio-button-wrapper:first-child {
				border-radius: 4px;
				margin-right: 10px;
			}

			.ant-radio-button-wrapper:hover {
				background: $dark-pink;
				border: 0px solid $dark-pink;
				color: white;
			}

			.ant-radio-button-wrapper-checked {
				background: $dark-pink;
				border: 0px solid $dark-pink;
				color: white;
				box-shadow: none;
			}
		}

		.zipcode {
			.ant-input-group-addon {
				background: $dark-pink;
				color: white;
				cursor: pointer;

				.cross-icon{
					line-height: 30px;
				}
			}
			.ant-input-group-addon:hover {
				background: $dark-pink;
				background: $dark-gray;
			}
		}	

		.tags-wrapper {
			font-size: 18px;
    		font-weight: 600;
		}	

		.dates-select-wrapper {
			font-size: 18px;
    		font-weight: 600;
		}

		.address-msg {
			color: green;
			margin-top: -30px;

		}
	}

	.dob-wrapper {
		display: flex;
		flex-direction: row;
		align-items: flex-start;
		flex-wrap: wrap;
		justify-content: flex-start;

		.date-option {
			margin-right: 10px;
		}

		.ant-select-selection-selected-value {
			font-size: 18px;
			font-weight: 600;
		}
	}

	.submit-item {
		text-align: left;
		display: flex;
		flex-direction: row;

		.valider {
			color: #fff;
			background: $dark-pink;
			border: 2px solid $dark-pink ;
			border-radius: 4px;
			margin-right: 10px;
			cursor: pointer;
			height: 40px;
		}
		.valider:hover {
			background: $dark-gray;
			border: 2px solid $dark-gray;
		}
		.anuler {
			color: #333;
			background: #C0C0C0;
			border-bottom: 4px solid;
			border-radius: 4px;
			margin-right: 10px;
			cursor: pointer;
			height: 40px;
		}
	}
}