.create-clients-wrapper {
    .ant-select-focused .ant-select-selection,
    .ant-select-selection:focus,
    .ant-select-selection:active {
        border-color: #fff !important;
        box-shadow: rgb(229, 0, 81) 0px 0px 10px 0px !important;
    }

    .form-control:focus {
        box-shadow: rgb(229, 0, 81) 0px 0px 10px 0px !important;
    }

    .no-margin-bottom {
        margin-bottom: 0;
    }

    .unsub {
        font-size: 0.7em;
        font-style: italic;
        color: rgb(229, 0, 81);
    }
}
