.selection-modal{
	display: flex;
	height: 70px;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	.body-text{
		margin: 0px;
		padding: 0px;
		text-align: center;
	}
	.ant-modal-body{
		margin: 0px;
		padding: 0px;
	}
	.btns-wrapper{
		margin-top: 40px;
		display: flex;
		justify-content: center;
		align-items: center;
		//padding-right: 10px;
		height: 5px;
	
		.valider {
			//width: 130px;
			color: #fff;
			width: auto;
			background: $dark-pink;
			padding: 10px 25px 10px 25px;
			margin-right: 20px;
			margin-left: 20px;
			margin-bottom: 25px;
			//border: 2px solid $dark-pink;
			//border-radius: 4px;
			cursor: pointer;
			//height: 40px;			
			//margin-left: 40px;
		}
	}
}
.ant-modal-title{
	text-align: center;
	color: white;
	font-weight: normal;
	letter-spacing: 2px;
}