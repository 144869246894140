.import-sms-wrapper1{
	display: flex;
	justify-content: flex-start;
	margin-top: 20px;
	.sms-heading{
		display: flex;
		justify-content: flex-start;
		//font-weight: 500;
		font-size: 20px;
	}
	.sms-secd-heading{
		display: flex;
		justify-content: flex-start;
		font-weight: 500;
		font-size: 20px;
		margin-bottom: 20px;
	}
	.label-wrapper{
		display: flex;
		justify-content: space-between;
		border-bottom: 1px solid #CCC;
		margin-bottom: 6px;

		.field-label{
		    color: rgba(0, 0, 0, 0.65);
		    font-weight: bold;
		    margin-bottom: -2px;

		    .colored-label {
		    	color: #d1245e;
		    }
		}
	}
	.input-date-field{

		.date-time-icons {
		    display: flex;
		    align-items: center;
		    padding: .375rem .75rem;
		    font-size: 1rem;
		    border-radius: .25rem;
	        background-color: #e50051;
		    border: none;
		    color: white;		

		    img {
		    	height: 20px;
		    }    
		}
		.date-icons{
			border-top-right-radius: 0;
    		border-bottom-right-radius: 0;
		}		
		.time-icon {
			border-top-left-radius: 0;
    		border-bottom-left-radius: 0;
		}
		.below-field-label {
			font-weight: 400px;
			font-size: 14px;
			float: left;
			color: #898889;
			margin-top: -3px;
		}
	}
	.text-wrapper{
		margin-top: 50px;
		.first-heading{
			font-size: 35px;
			.colored-text{
				color: $dark-pink;
			}
		}
		.second-heading{
			font-size: 20px;
		}
	}
	.media-file-name{
		margin-top: 20px;
	}
	.btns-wrapper{
		margin-top: 20px;
		display: flex;
		justify-content: center;
		align-items: center;
		padding-right: 10px;
	
	.valider {
		//width: 130px;
		color: #fff;
		width: auto;
		background: $dark-pink;
		//padding: 10px 25px 10px 25px;
		padding: 6px 15px 4px 15px;
		cursor: pointer;
		margin-right: 20px;
		margin-left: 20px;
		margin-bottom: 25px;
		border: 2px solid $dark-pink;
		border-radius: 0px;
		cursor: pointer;
		//height: 40px;			
		//margin-left: 40px;
	}
	.anular {
		//width: 130px;
		color: #fff;
		width: auto;
		background: #43444a;
		padding: 10px 25px 10px 25px;
		margin-right: 20px;
		margin-left: 20px;
		margin-bottom: 25px;
		//border: 2px solid $dark-pink;
		//border-radius: 4px;
		cursor: pointer;
		//height: 40px;			
		//margin-left: 40px;
	}
}
.ant-progress-inner{
	border-radius: 0px;
	border: 1px solid black;
}
.ant-progress-text{
	color: $dark-pink;
	font-weight: 600;
	font-size: 14px;
}
.myProgress {
  width: 100%;
  background-color: grey;
  .myBar {
	  width: 1%;
	  height: 30px;
	  background-color: green;
	}
}
.last-headings-wrapper{
	margin-top: 20px;
	line-height: 20px;
	.first-text{
		.bold-text{
			font-weight: bold;
		}
	}
	.second-text{
		.bold-text{
			font-weight: bold;
		}
	}
}
}