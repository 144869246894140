.new-campagnes-wrapper {
    .content-wrapper {
        .ant-select-open .ant-select-selection {
            border-color: #e50051 !important;
            box-shadow: rgb(229, 0, 81) 0px 0px 10px 0px !important;
        }

        .ant-select-focused .ant-select-selection,
        .ant-select-selection:focus,
        .ant-select-selection:active {
            border-color: #e50051 !important;
            box-shadow: rgb(229, 0, 81) 0px 0px 10px 0px !important;
        }

        .ant-time-picker-input:focus {
            border-color: #fff !important;
            box-shadow: rgb(229, 0, 81) 0px 0px 10px 0px !important;
        }
    }
}
