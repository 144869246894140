.sms-vocal-list-wrapper {
    display: flex;
    justify-content: flex-start;
    margin-top: 20px;

    .ant-radio-checked .ant-radio-inner {
        border-color: #f389ae;
    }

    .ant-radio-wrapper:hover .ant-radio,
    .ant-radio:hover .ant-radio-inner,
    .ant-radio-input:focus + .ant-radio-inner {
        border-color: #f389ae;
    }

    .ant-radio-inner::after {
        background-color: #e50051;
    }

    .sms-heading {
        display: flex;
        justify-content: flex-start;
        //font-weight: 500;
        font-size: 20px;
    }
    .sms-secd-heading {
        display: flex;
        justify-content: flex-start;
        font-weight: 500;
        font-size: 20px;
        margin-bottom: 20px;
    }
    .label-wrapper {
        display: flex;
        justify-content: space-between;
        border-bottom: 1px solid #ccc;
        margin-bottom: 6px;

        .field-label {
            color: rgba(0, 0, 0, 0.65);
            font-weight: bold;
            margin-bottom: -2px;

            .colored-label {
                color: #d1245e;
            }
        }
    }
    .input-date-field {
        .date-time-icons {
            display: flex;
            align-items: center;
            padding: 0.375rem 0.75rem;
            font-size: 1rem;
            border-radius: 0.25rem;
            background-color: #e50051;
            border: none;
            color: white;

            img {
                height: 20px;
            }
        }
        .date-icons {
            border-top-right-radius: 0;
            border-bottom-right-radius: 0;
        }
        .time-icon {
            border-top-left-radius: 0;
            border-bottom-left-radius: 0;
        }
        .below-field-label {
            font-weight: 400px;
            font-size: 14px;
            float: left;
            color: #898889;
            margin-top: -3px;
        }
    }
    .sms-list-wrapper {
        margin-top: 20px;
        display: flex;
        justify-content: flex-start;
        .sms-list-heading {
            color: white;
            font-size: 20px;
            background: #e50051;
            padding: 10px 85px 10px 85px;
        }
    }
    .content-wrapper {
        //padding: 40px 50px;
        margin-top: 30px;
        .segments-table {
            .ant-table-thead > tr > th {
                font-size: 12px;
                background: #e50051;
                padding-top: 10px;
                padding-bottom: 10px;
                color: white;
                font-size: 16px;
            }
            .actions-wrapper {
                display: flex;
                flex-direction: row;
                align-items: center;
                justify-content: space-around;

                .nav-link {
                    color: rgba(0, 0, 0, 0.65);
                }
                .action-icon {
                    cursor: pointer;
                }
            }

            .status-switch {
                width: 68px;
            }
        }
    }

    .bottom-wrapper {
        display: flex;
        justify-content: space-between;
        align-items: flex-end;
        .icons-wrapper {
            display: flex;
            align-items: center;
            .image {
                //margin-top: 30px;
                display: flex;
                justify-content: flex-end;
                align-items: flex-start;
                flex-direction: column;
                //float: right;
                img {
                    height: 24px;
                    margin-top: 5px;
                    width: 24px;
                }
            }
            .text-wrapper {
                //margin-top: 30px;
                margin-left: 10px;
                display: flex;
                justify-content: flex-start;
                flex-direction: column;
                float: left;
                .play-text {
                    display: flex;
                    justify-content: flex-start;
                    margin-top: 5px;
                }
                .marked-text {
                    display: flex;
                    justify-content: flex-start;
                    margin-top: 5px;
                }
                .delete-text {
                    display: flex;
                    justify-content: flex-start;
                    margin-top: 7px;
                }
            }
        }

        .buttns-wrapper {
            display: flex;
            justify-content: center;
            align-items: center;

            .valider {
                color: #fff;
                width: auto;
                background: $dark-pink;
                padding: 10px 25px 32px 25px;
                margin-left: 25px;
                border-radius: 0px;
                cursor: pointer;
                font-size: 16px;
                &.disabled {
                    background: #ccc;
                }
            }
            .anular {
                padding: 10px 25px;
                cursor: pointer;
                background: #43444a;
                color: #fff;
                width: auto;
            }
        }
    }
}
